import React from 'react';

import '../styles/style.scss';
import Helmet from './helmet';
import Footer from './footer';
import Navbar from './navbar';

import CookieConsent from 'react-cookie-consent';

const Layout = ({ children, hideNavbar }) => (
	<div>
		<Helmet />
		{hideNavbar ? '' : <Navbar showLogo={true} />}
		{children}
		<Footer />
		<CookieConsent
			style={{ background: "#4C4C4CCC" }}
			buttonStyle={{ background: "#2f80edCC", color:"white" }}
			location="bottom"
			buttonText="Aceptar"
			cookieName="gatsby-gdpr-google-analytics">
			Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa navegando, consideramos que acepta su uso.
		</CookieConsent>

	</div>
);

export default Layout;
