import React from 'react'


const Footer = () => (
  <footer className='footer'>
    <div className='has-text-centered'>
        Axioma Consultors Acústics © 2020
    </div>
  </footer>
)

export default Footer
