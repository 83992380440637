import React from 'react';
import { slide as Menu } from "react-burger-menu";
import { Link, StaticQuery } from 'gatsby';
import logo from '../images/logo_30.png';
import './navbar.scss';


const Navbar = ({ showLogo }) => (
	<>
		<Menu right>
			<Link className="is-size-5" to="/" activeClassName="active">Home</Link>
			<Link className="is-size-5" to="/nosaltres" activeClassName="active" partiallyActive={true}>Nosaltres</Link>
			<Link className="is-size-5" to="/serveis" activeClassName="active" partiallyActive={true}>Serveis</Link>
			{/*
			<Link className="is-size-5" to="/projectes" activeClassName="active" partiallyActive={true}>Projectes</Link>-->
			<Link className="is-size-5" to="/blog" activeClassName="active" partiallyActive={true}>Blog</Link>
			<Link className="is-size-5" to="/contacte" activeClassName="active" partiallyActive={true}>Contacte</Link>
			*/}
			<a href="mailto:info@axioma-acustic.com" activeClassName="active" className="is-size-5">CONTACTE</a>
		</Menu>
		<nav className="navbar has-background-white">
			<div className="container">
				<div className="navbar-brand">
					{showLogo
						? <Link to="/" className="navbar-item">
							<img src={logo} alt="Actividades Online"></img><h1 className="title">Axioma</h1>
						</Link>

						: ''}
				</div>
				<div className="navbar-menu">
					<div className="navbar-end">
						<Link to="/" activeClassName="active">Home</Link>
						<Link to="/nosaltres" activeClassName="active" partiallyActive={true}>Nosaltres</Link>
						<div className="navbar-item has-dropdown is-hoverable">
							<StaticQuery
								query={graphql`
											query {
												allStrapiServices(
														sort: { fields: [order], order: ASC }
													) {
													edges {
														node {								
															name
															slug
														}
													}
												}
											}
										`}
								render={data => (
									<>
										<Link to={`/serveis/${data.allStrapiServices.edges[0].node.slug}`} activeClassName="active" partiallyActive={true}>Serveis</Link>
										<div className="navbar-dropdown">{
											data.allStrapiServices.edges.map((service, i) => {
												return <Link className="navbar-item" key={i} to={`/serveis/${service.node.slug}`}>{service.node.name}</Link>
											})
										}
										</div>
									</>
								)
								}
							/>
						</div>
						{/*
						<Link to="/projectes" activeClassName="active" partiallyActive={true}>Projectes</Link>
						<Link to="/blog" activeClassName="active" partiallyActive={true}>Blog</Link>
						<Link to="/contacte" activeClassName="active" partiallyActive={true}>Contacte</Link>
						 */}
						<a href="mailto:info@axioma-acustic.com" activeClassName="active">CONTACTE</a>
					</div>
				</div>
			</div>
		</nav>

	</>
);

export default Navbar;